const carreras = [
    {nombre:"Abogacia", wiseCode: "0020", cordoba: true, rioCuarto: false},
    {nombre:"Agronomía", wiseCode: "0001", cordoba: true, rioCuarto: false},
    {nombre:"Arquitectura", wiseCode: "0022", cordoba: true, rioCuarto: false},
    {nombre:"Bioquímica", wiseCode: "0015", cordoba: true, rioCuarto: false},
    {nombre:"Contador Público", wiseCode: "0013", cordoba: true, rioCuarto: false},
    {nombre:"Farmacia", wiseCode: "0016", cordoba: true, rioCuarto: false},
    {nombre:"Ingeniería Civil", wiseCode: "0010", cordoba: true, rioCuarto: false},
    {nombre:"Ingeniería Electrónica", wiseCode: "0005", cordoba: true, rioCuarto: false},
    {nombre:"Ingeniería en Computación", wiseCode: "0004", cordoba: true, rioCuarto: false},
    {nombre:"Ingeniería en Informática", wiseCode: "0003", cordoba: true, rioCuarto: true},
    {nombre:"Ingeniería Industrial", wiseCode: "0008", cordoba: true, rioCuarto: true},
    {nombre:"Ingeniería Mecánica", wiseCode: "0009", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Administración de Empresas", wiseCode: "0014", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Bioinformática", wiseCode: "0019", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Ciencia Política", wiseCode: "0006", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Ciencias de la Educación", wiseCode: "0025", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Ciencias de la Educación (Ciclo)", wiseCode: "0039", cordoba: true, rioCuarto: true},
    {nombre:"Licenciatura en Diseño", wiseCode: "0023", cordoba: true, rioCuarto: true},
    {nombre:"Licenciatura en Enfermería", wiseCode: "0034", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Filosofía", wiseCode: "0012", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Instrumentación Quirúrgica", wiseCode: "0031", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Kinesiología y Fisiatría", wiseCode: "0033", cordoba: true, rioCuarto: true},
    {nombre:"Licenciatura en Nutrición", wiseCode: "0030", cordoba: true, rioCuarto: true},
    {nombre:"Licenciatura en Psicología", wiseCode: "0024", cordoba: true, rioCuarto: true},
    {nombre:"Licenciatura en Psicopedagogía", wiseCode: "0027", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Relaciones Internacionales", wiseCode: "0007", cordoba: true, rioCuarto: true},
    {nombre:"Licenciatura en Teología", wiseCode: "0011", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Terapia Ocupacional", wiseCode: "0032", cordoba: true, rioCuarto: false},
    {nombre:"Licenciatura en Tecnología de los Alimentos", wiseCode: "0017", cordoba: true, rioCuarto: false},
    {nombre:"Médicina", wiseCode: "0028", cordoba: true, rioCuarto: false},
    {nombre:"Notariado", wiseCode: "0021", cordoba: true, rioCuarto: false},
    {nombre:"Odontólogia", wiseCode: "0029", cordoba: true, rioCuarto: false},
    {nombre:"Profesorado en Ciencias de la Educación", wiseCode: "0026", cordoba: true, rioCuarto: false},
    {nombre:"Profesorado Universitario (Ciclo)", wiseCode: "0040", cordoba: true, rioCuarto: false},
    {nombre:"Tecnicatura en Gestión Gerencial", wiseCode: "0041", cordoba: true, rioCuarto: true},
    {nombre:"Tecnicatura en Marketing Digital", wiseCode: "0035", cordoba: true, rioCuarto: true},
    {nombre:"Tecnicatura en Administración de Personal", wiseCode: "0036", cordoba: true, rioCuarto: true},
    {nombre:"Tecnicatura en Administración y Marketing de Agronegocios", wiseCode: "0037", cordoba: true, rioCuarto: true},
    {nombre:"Tecnicatura en Gestión del Clima Laboral en la Organización", wiseCode: "0038", cordoba: true, rioCuarto: true},
    {nombre:"Tecnicatura Universitaria en Análisis y Control de Calidad de Alimentos", wiseCode: "0042", cordoba: true, rioCuarto: true},
    {nombre:"Tecnicatura Universitaria en Cosmetología y Cosmiatría", wiseCode: "0018", cordoba: true, rioCuarto: true},
    {nombre:"Veterinaria", wiseCode: "0002", cordoba: true, rioCuarto: false},
]

export default carreras