import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'gatsby'
import { getDateFormatted } from '../helpers/getDateFormatted'
import './font-awesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1366 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 1366, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 764 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 764, min: 0 },
    items: 1
  }
}
function CarouselReact({ diaries, isHomeUcc }) {
  return (
    <Carousel responsive={responsive}>
      {diaries.map((diary) => {
        // Formto de la Fecha
        const objDate = getDateFormatted(new Date(diary.date))
        // Creando el objeto con la Fecha y Hora del evento
        const setDate = diary.date + " " + diary.time

        return (
          <div
            key={`diaresId-${diary.id}`}
            className={`mx-2 p-4 h-100 rounded shadow-sm ${
              (new Date(setDate) < new Date()) ? 'disabled' : ' bg-white'
            }`}
          >
            <Link to={`/agenda/${diary.slug}`} className="text-decoration-none">
              <div className="text-center text-uppercase heigth-multiple-carousel d-flex flex-column align-items-center justify-content-center py-2 border-bottom">
                <h6
                  className={`font-weight-bold ${
                    isHomeUcc ? 'ucc-text-blue' : ' '
                  }`}
                >
                  {objDate.day}
                </h6>
                <h1
                  className={`font-weight-bold ${
                    isHomeUcc ? 'ucc-text-blue' : ' '
                  }`}
                >
                  {objDate.numberDay}
                </h1>
                <h6
                  className={`font-weight-bold ${
                    isHomeUcc ? 'ucc-text-blue' : ' '
                  }`}
                >
                  {objDate.mounth}
                </h6>
              </div>
              <div className="text-uppercase h-50 d-flex flex-column align-items-center justify-content-center pt-2">
                <ReactMarkdown
                  source={diary.title}
                  className={`font-weight-bold ${
                    isHomeUcc ? 'ucc-text-blue' : ' '
                  }`}
                />
              </div>
              <h6 className='agenda-location'>
                <FontAwesomeIcon
                  icon={['fas', 'map-marker-alt']}
                  size="lg"
                  className={`mr-1 ${isHomeUcc ? 'ucc-text-blue' : ''}`}
                />
                <span
                  className={`font-weight-bold text-capitalize ${
                    isHomeUcc ? 'ucc-text-blue' : ''
                  }`}
                >
                  {diary.place}
                </span>
              </h6>
            </Link>
          </div>
        )
      })}
    </Carousel>
  )
}

export default CarouselReact

CarouselReact.propTypes = {
  diaries: PropTypes.array.isRequired,
  isHomeUcc: PropTypes.bool
}
