import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Carousel from './react-carousel'

const Schedule = ({ diaries, isHomeUcc = false, title }) => {
  if(!diaries) return null

  // Ordenando los eventos activos segun su fecha - Activos de forma ASC y Terminados de forma DESC
  diaries.sort(function(diary) {
    // Creando el objeto con la Fecha y Hora del evento
    const setDate = diary.date + " " + diary.time

    if (new Date(setDate) > new Date()) {
      return -1
    }
    else { 
      return 1
    }
  });

  return (
    diaries.length && (
      <div className="background-dark my-5">
        <div className="container mx-auto w-100 pb-4 pt-4">
          <div className="d-flex align-items-center justify-content-between mb-3">
            {isHomeUcc ? (
              <h2 className="text-uppercase text-white font-weight-extra-bold mb-0">
                {title ? title : 'AGENDA'}{' '}
              </h2>
            ) : (
              <h5 className="text-uppercase text-white font-weight-extra-bold">
                {title ? title : 'AGENDA'}{' '}
              </h5>
            )}
            {diaries.some((diary) => new Date(diary.date) >= new Date()) && <Link
              to={!isHomeUcc ? './agenda' : '/agenda'}
              className={`btn btn-sm btn-outline-light ml-2 ${
                isHomeUcc ? 'rounded-pill px-3' : ''
              }`}
            >
              Ver agenda completa
            </Link>}
          </div>
          <div id="schedule-component" className="mx-n2">
            <Carousel diaries={diaries} isHomeUcc={isHomeUcc} />
          </div>
        </div>
      </div>
    )
  )
}

export default Schedule

Schedule.propTypes = {
  diaries: PropTypes.array,
  isHomeUcc: PropTypes.bool,
  title: PropTypes.string
}
